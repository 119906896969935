// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const checkToday = date => {
  return isToday(new Date(date))
}

export const fetchPhonePrefix = async () => {
  let phone_pre = '1';
  const countryPhonePrefixes = {
    'afg': '93', 'alb': '355', 'dza': '213', 'asm': '1-684', 'and': '376', 'ago': '244', 'aia': '1-264',
    'ata': '672', 'atg': '1-268', 'arg': '54', 'arm': '374', 'abw': '297', 'aus': '61', 'aut': '43',
    'aze': '994', 'bhs': '1-242', 'bhr': '973', 'bgd': '880', 'brb': '1-246', 'blr': '375', 'bel': '32',
    'blz': '501', 'ben': '229', 'bmu': '1-441', 'btn': '975', 'bol': '591', 'bih': '387', 'bwa': '267',
    'bra': '55', 'iot': '246', 'vgb': '1-284', 'brn': '673', 'bgr': '359', 'bfa': '226', 'bdi': '257',
    'khm': '855', 'cmr': '237', 'can': '1', 'cpv': '238', 'cym': '1-345', 'caf': '236', 'tcd': '235',
    'chl': '56', 'chn': '86', 'col': '57', 'com': '269', 'cog': '242', 'cod': '243', 'cok': '682',
    'cri': '506', 'hrv': '385', 'cub': '53', 'cyp': '357', 'cze': '420', 'dnk': '45', 'dji': '253',
    'dma': '1-767', 'dom': '1-809', 'ecu': '593', 'egy': '20', 'slv': '503', 'gnq': '240', 'eri': '291',
    'est': '372', 'swz': '268', 'eth': '251', 'fro': '298', 'fji': '679', 'fin': '358', 'fra': '33',
    'pyf': '689', 'gab': '241', 'gmb': '220', 'geo': '995', 'deu': '49', 'gha': '233', 'gib': '350',
    'grc': '30', 'grl': '299', 'grd': '1-473', 'gum': '1-671', 'gtm': '502', 'gin': '224', 'gnb': '245',
    'guy': '592', 'hti': '509', 'hnd': '504', 'hkg': '852', 'hun': '36', 'isl': '354', 'ind': '91',
    'idn': '62', 'irn': '98', 'irq': '964', 'irl': '353', 'isr': '972', 'ita': '39', 'civ': '225',
    'jam': '1-876', 'jpn': '81', 'jor': '962', 'kaz': '7', 'ken': '254', 'kir': '686', 'prk': '850',
    'kor': '82', 'kwt': '965', 'kgz': '996', 'lao': '856', 'lva': '371', 'lbn': '961', 'lso': '266',
    'lbr': '231', 'lby': '218', 'lie': '423', 'ltu': '370', 'lux': '352', 'mac': '853', 'mdg': '261',
    'mwi': '265', 'mys': '60', 'mdv': '960', 'mli': '223', 'mlt': '356', 'mhl': '692', 'mrt': '222',
    'mus': '230', 'mex': '52', 'fsm': '691', 'mda': '373', 'mco': '377', 'mng': '976', 'mne': '382',
    'msr': '1-664', 'mar': '212', 'moz': '258', 'mmr': '95', 'nam': '264', 'nru': '674', 'npl': '977',
    'nld': '31', 'ncl': '687', 'nzl': '64', 'nic': '505', 'ner': '227', 'nga': '234', 'niu': '683',
    'nfk': '672', 'mkd': '389', 'nor': '47', 'omn': '968', 'pak': '92', 'plw': '680', 'pse': '970',
    'pan': '507', 'png': '675', 'pry': '595', 'per': '51', 'phl': '63', 'pcn': '64', 'pol': '48',
    'prt': '351', 'pri': '1-787', 'qat': '974', 'rou': '40', 'rus': '7', 'rwa': '250', 'blm': '590',
    'shn': '290', 'kna': '1-869', 'lca': '1-758', 'maf': '590', 'vct': '1-784', 'wsm': '685', 'smr': '378',
    'stp': '239', 'sau': '966', 'sen': '221', 'srb': '381', 'syc': '248', 'sle': '232', 'sgp': '65',
    'sxm': '1-721', 'svk': '421', 'svn': '386', 'slb': '677', 'som': '252', 'zaf': '27', 'ssd': '211',
    'esp': '34', 'lka': '94', 'sdn': '249', 'sur': '597', 'swe': '46', 'che': '41', 'syr': '963',
    'twn': '886', 'tjk': '992', 'tza': '255', 'tha': '66', 'tls': '670', 'tgo': '228', 'ton': '676',
    'tto': '1-868', 'tun': '216', 'tur': '90', 'tkm': '993', 'tuv': '688', 'uga': '256', 'ukr': '380',
    'are': '971', 'gbr': '44', 'usa': '1', 'ury': '598', 'uzb': '998', 'vut': '678', 'vat': '379',
    'ven': '58', 'vnm': '84', 'yem': '967', 'zmb': '260', 'zwe': '263'
  };
  try {
    const axios = (await import('axios')).default;
    const response = window.sessionStorage.getItem('countryCode')
      ? { data: { country: window.sessionStorage.getItem('countryCode') } }
      : await axios.get('https://cmp.inmobi.com/geoip');

    if (!window.sessionStorage.getItem('countryCode')) {
      const countryCode = response.data.country.toLowerCase();
      window.sessionStorage.setItem('countryCode', countryCode);
    }

    const countryCode = response.data.country.toLowerCase();
    const phonePrefix = countryPhonePrefixes[countryCode];
    if (phonePrefix) {
      phone_pre = phonePrefix;
    } else {
      console.error('Phone number not found for this country');
    }
  } catch (error) {
    console.error('Unable to get geographic data:', error);
  }
  return phone_pre;
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDTime = (value) => {
  if (!value) return value
  const position = value.indexOf(' ') + 1
  return value.substring(position, position + 8)
}

export const checkImage = (url) => {
  var s = document.createElement("IMG")
  s.src = url
  s.onerror = function () {
    console.log("file with " + url + " invalid")
    return 'https://erp.cloodo.com/img/default-profile-3.png'
  }
  s.onload = function () {
    console.log("file with " + url + " valid")
    return url
  }
}

const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const CustomAvatar = ({ name, avatarUrl, size = '42px', align = 'left', margin, fontSize = '16px', height = '100%' }) => {
  const defaultAvatar = 'https://erp.cloodo.com/img/avatar.png';
  const isDefaultAvatar = !avatarUrl || avatarUrl === defaultAvatar;
  const backgroundColor = name ? stringToColor(name) : '#ccc';

  const getInitials = name => {
    if (!name) return '';
    name = name.replace(/^[^a-zA-Z0-9]+/, '');
    const initials = name.split(' ').map(word => word[0]);
    return initials.join('').toUpperCase();
  };

  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: fontSize,
    color: 'white',
    border: '2px solid #fff',
    overflow: 'hidden'
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: align === 'center' ? 'center' : (align === 'left' ? 'flex-start' : 'flex-end'),
    margin: margin
  };

  return (
    <div style={containerStyle}>
      <div style={avatarStyle}>
        {isDefaultAvatar && name
          ? <span>{getInitials(name)}</span>
          : <img src={avatarUrl} alt="Avatar" style={{ width: '100%', height: height, borderRadius: '50%' }} />}
      </div>
    </div>
  );
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUid = () => {
  let iuds = getUserData()?.iuds;
  if (iuds) {
    return parseInt(Buffer.from(iuds, 'base64').toString());
  }
  return null;
};
export const getHearderAuth = (includeCompanyToken = true, additionalHeaders = []) => {
  const jwt_user = getUserData()?.data.token;
  const token_company = localStorage?.getItem("token_company");
  const headers = {
    Authorization: `Bearer ${jwt_user}`
  };

  if (includeCompanyToken && token_company) {
    headers["X-WorkSuite-Company-Token"] = token_company;
  }

  // Add additional headers if provided
  additionalHeaders.forEach(header => {
    headers[header.key] = header.value;
  });

  return headers;
};

export function getPopupWhatsAppIntegration() {
  return {
    title: 'WhatsApp Integration',
    description: 'Connect your WhatsApp account to start using the platform’s features. If your WhatsApp connection is lost due to inactivity for over 14 days as per Meta\'s policy, please reconnect to continue using all features.',
    buttonText: 'Connect or Reconnect WhatsApp',
    buttonOnClick: () => {
      window.location.href = '/apps/settings/whatsapp-connect';
    }
  };
};

export function getTrialPopupContent() {
  return {
    title: 'Unlock Premium Features',
    description: 'Discover the full potential of WACRM with our premium plan! Unlock advanced features, enhance your workflow, and take your business to the next level.',
    buttonText: 'Unlock Premium Access',
    buttonOnClick: () => {
      window.location.href = 'https://cloodo.com/project/whatsapp-crm#pricing';
    }
  };
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/apps/dashboard/company'
  return '/'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})
